<template>
  <section class="container">
    <stockTopMenu windowId="expressDelivery" module="expressage"></stockTopMenu>
    <div style="background: #fff;margin: 20px;">
      <el-form inline style="width: 100%; height: 100%; padding: 25px 20px 5px;" :model="form" size="small">
        <el-form-item label="物流公司" v-if="1===2">
          <el-input clearable placeholder="请输入" v-model="form.snList"></el-input>
        </el-form-item>
        <el-form-item label="运单号">
          <el-input clearable placeholder="请输入" v-model="form.waybillNo"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input clearable placeholder="请输入寄件人/收件人姓名" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input clearable placeholder="请输入寄件人/收件人手机号" v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryList">查询</el-button>
          <el-button @click="reset">重置</el-button>
          <el-button type="primary" @click="addVisible = true">
            <i class="el-icon-plus"></i>
            新增寄件
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin: 0 20px;padding: 5px;background: #fff;">
      <grid-manager :option="gridOptions"></grid-manager>
    </div>
    <InitiateMail v-if="addVisible" @close="addVisible = false" @refresh="queryList"></InitiateMail>
    <TrackDialog v-if="trackVisible" :expressNo="expressNo" @close="trackVisible = false"></TrackDialog>
  </section>
</template>

<script>
import stockTopMenu from '@/components/stockTopMenu'
import InitiateMail from "./components/initialMail.vue"
import TrackDialog from "./components/trackDialog"
import {erpWaybillQuery} from "@/libs/http/modules/erp"
import {erpPackageRecallJdExpress} from "@/libs/http/api";
export default {
  components: {
    stockTopMenu,
    InitiateMail,
    TrackDialog
  },
  data() {
    return {
      addVisible: false,
      trackVisible: false,
      expressNo: {},
      form: {
        waybillNo: '',
        name: '',
        phone: ''
      },
      gridOptions: {
        gridManagerName: 'expressDeliveryList',
        firstLoading: true,
        isCombSorting: false,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        dataKey: 'items',
        totalsKey: 'totalItem',
        lineHeight: '30px',
        pageSize: 20,
        sizeData: [20, 50, 100, 500],
        columnData: [
          {
            key: 'receiveType',
            text: '收货方类型',
            template: (cell) => {
              let cellText = {
                '1': '公司',
                '2': '其他',
                '3': '店铺'
              }
              return cellText[cell] || ''
            }
          },
          { key: 'description', text: '物品名称', width: '150px' },
          { key: 'logisticsCompany', text: '物流公司'},
          { key: 'waybillNo', text: '运单号', width: '160px'},
          { key: 'senderName', text: '寄件人', width: '120px'},
          { key: 'senderMobile', text: '寄件人手机号', width: '120px'},
          { key: 'senderAddress', text: '寄件地址', width: '300px'},
          { key: 'receiveName', text: '收件人', width: '120px'},
          { key: 'receiveMobile', text: '收件人手机号', width: '120px'},
          { key: 'receiveAddress', text: '收件地址', width: '300px'},
          {
            key: 'state',
            text: '状态',
            width: '100px',
            template: () => {
              return `
                  <el-tag size="small" type="info" v-if="row.state === '-1'">已取消</el-tag>
                  <el-tag size="small" type="success" v-else>已下单</el-tag>
              `
            }
          },
          { key: 'createUser', text: '创建人', width: '120px'},
          { key: 'createTime', text: '创建时间', width: '180px'},
          {
            key: 'action',
            text: '操作',
            fixed: 'right',
            width: '150px',
            template: () => {
              return `
                <div>
                   <span style="color: #0b83f3 !important;cursor:pointer;margin-right: 10px;" @click="cancelHandel(row)" v-if="row.type === '1'">取消</span>
                   <span style="color: #0b83f3 !important;cursor:pointer;" @click="showTrack(row)">物流轨迹</span>
                </div>
              `
            }
          }
        ],
        ajaxData: this.fetch,
        supportAjaxPage: true,
        height: '79vh'
      }
    }
  },
  methods: {
    fetch(params) {
      let reqData = {
        page: params.pageData.cPage,
        pageSize: params.pageData.pSize,
        senderType: '3',
        senderObject: this.$cache.local.get('marketingUnitId'),
        ...this.form
      }
      return erpWaybillQuery(reqData);
    },
    queryList(){
      GridManager.refreshGrid(this.gridOptions.gridManagerName, true)
    },
    reset(){
      for (let key in this.form){
        this.form[key] = ''
      }
      GridManager.refreshGrid(this.gridOptions.gridManagerName, true)
    },
    cancelHandel(row){
      this.$confirm('确认取消该快递单', '提示').then(res=>{
        erpPackageRecallJdExpress({
          waybillCode: row.waybillNo
        }).then(res=>{
          GridManager.refreshGrid(this.gridOptions.gridManagerName)
          this.$message({ type: 'success', message: '快递单取消成功', duration: 2000, showClose: true });
        }).catch(error=>{
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true });
        })
      }).catch(err=>{})
    },
    showTrack(row){
      this.expressNo = row
      this.trackVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>